import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Image from "reusecore/src/elements/Image";
import Input from "reusecore/src/elements/Input";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/UI/Container";
import Particles from "../Particle";
import BannerWrapper, {
  DiscountLabel,
  BannerObject,
  AccessForm,
  StyledButton
} from "./bannerSection.style";

import BannerObject1 from "../../../assets/image/saas/banner/bannerObject1.png";
import BannerObject2 from "../../../assets/image/saas/banner/hero.png";
import { fromPromise } from "apollo-link";

class BannerSection extends Component {
  state = {
    isSubmitted: false
  };

  render() {
    const {
      row,
      col,
      title,
      btnStyle,
      description,
      discountText,
      discountAmount,
      outlineBtnStyle,
      button,
      handleRequestForm
    } = this.props;
    return (
      <BannerWrapper id="banner_section">
        <Particles />
        <Container>
          <Box className="row" {...row}>
            <Box className="col" {...col}>
              <DiscountLabel>
                <Text content="Closed beta in 2019" {...discountAmount} />
              </DiscountLabel>
              <FeatureBlock
                title={
                  <Heading
                    content="The ultimate workflow for software engineering managers."
                    {...title}
                  />
                }
                description={
                  <Text
                    content="Devmanager helps you organize, track, plan and lead the professional development of your team."
                    {...description}
                  />
                }
                // button={<ButtonGroup />}
              />
              <AccessForm
                name="request_access"
                method="post"
                data-netlify="true"
                action="/success"
                data-netlify-honeypot="bot-field"
                netlify
              >
                <Box className="row" {...row}>
                  <Box className="col" {...col}>
                    <input
                      type="hidden"
                      name="form-name"
                      value="request_access"
                    />
                    <Input
                      inputType="email"
                      placeholder="Your email address"
                      iconPosition="right"
                      isMaterial={false}
                      className="email_input"
                      name="email"
                      label="email"
                    />
                  </Box>
                  <Box className="col" {...col}>
                    <StyledButton
                      {...button}
                      title="Request access"
                      type="submit"
                    />
                  </Box>
                </Box>
              </AccessForm>
            </Box>
          </Box>
        </Container>
        <BannerObject>
          <div className="objectWrapper">
            <Image src={BannerObject1} alt="BannerObject1" />
            <div className="dashboardWrapper">
              <Image src={BannerObject2} alt="BannerObject2" />
            </div>
          </div>
        </BannerObject>
      </BannerWrapper>
    );
  }
}

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center"
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, "70%", "50%", "45%"]
  },
  title: {
    fontSize: ["22px", "34px", "30px", "55px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.5"
  },
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "1.75",
    mb: "0"
  },
  btnStyle: {
    minWidth: ["120px", "120px", "120px", "156px"],
    fontSize: ["13px", "14px"],
    fontWeight: "500",
    colors: "primaryWithBg"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5167db",
    ml: "18px"
  },
  discountAmount: {
    fontSize: "14px",
    color: "#eb4d4b",
    mb: 0,
    as: "span",
    mr: "0.4em",
    fontWeight: 700
  },
  discountText: {
    fontSize: ["13px", "14px"],
    color: "#0f2137",
    mb: 0,
    as: "span",
    fontWeight: 500
  }
};

export default BannerSection;
